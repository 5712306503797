$(function() {
	// Bootstrap opt-ins
	$("[data-toggle=popover]").popover();
	$("[data-toggle=tooltip]").tooltip();

	// Edit switch
	$('.edit-switch').hover(function() {
		$(this).find('.editable').hide();
		$(this).find('.editable-actions').show();
	}, function() {
		$(this).find('.editable-actions').hide();
		$(this).find('.editable').show();
	});


	// window.Pikaday = require('pikaday');

	// Graphing
	// Minor offset in lines
	// to handle the edge case when data points match exactly for different lines
	// and we don't want the lines to overlap
	/*
	$('g.c3-shapes.c3-lines').each(function(index) {
		if ($(this).closest('.no-offset').length > 0) {
			return $(this);
		}

		var offset = index * 2;
		$(this).attr('transform', 'translate('+offset+','+offset+')');
	});
	*/
});
