(function() {
  var createStorageKey, uploadAttachment;

  document.addEventListener("trix-attachment-add", function(event) {
    var attachment;
    attachment = event.attachment;
    if (attachment.file) {
      var $editor = $(event.target).first();
      var $form = $editor.closest('form');
      return uploadAttachment(attachment, $form);
    }
  });

  uploadAttachment = function(attachment, $form) {
    var $submit = $form.find('input[type="submit"]:first');
    if (!$submit.data('original_value')) {
      $submit.data('original_value', $submit.val());
    }
    
    $submit
      .attr('disabled', true)
      .val('Uploading...');

    var file, form, key, xhr;
    file = attachment.file;
    key = createStorageKey(file);
    form = new FormData;
    form.append("key", key);
    form.append("Content-Type", file.type);
    form.append("file", file);
    xhr = new XMLHttpRequest;
    xhr.open("POST", host, true);
    xhr.upload.onprogress = function(event) {
      var progress;
      progress = event.loaded / event.total * 100;
      return attachment.setUploadProgress(progress);
    };
    xhr.onload = function() {
      var href, url;

      // trix default code requires 204
      if (xhr.status === 200) {
        $submit
          .attr('disabled', false)
          .val($submit.data('original_value'));

          //Replaced original code - used xhr.responseUrl which IE cannot use
          //url = href = host + key;
          //url = href = xhr.responseURL;
          var jsonresponse = JSON.parse(xhr.response);
        url = href = jsonresponse.document;
        return attachment.setAttributes({
          url: url,
          href: href
        });
      }else{
          var jsonresponse = JSON.parse(xhr.response);
          alert(jsonresponse.message)
          location.reload();
      }
    };
    return xhr.send(form);
  };

  createStorageKey = function(file) {
    var date, day, time;
    date = new Date();
    day = date.toISOString().slice(0, 10);
    time = date.getTime();
    return "tmp/" + day + "/" + time + "-" + file.name;
  };

}).call(this);

$(function() {

    //This relates to the trix local storage below. If a form is submitted on a page, clear out the trix local storage
    //for any trix editor on this page.
    $("form").each(function() {
        $(this).on("submit", function(){
            $('trix-editor').each(function () {
                var trix_actual_id = $(this).attr('id');
                localStorage.removeItem(trix_actual_id);
            });
        });
    });

    // Add input[type=file] field to Trix editor
    $('trix-editor').each(function () {
        var editor = $(this).get(0).editor;
        var trix_id = $(this).attr('input');
        var trix_actual_id = $(this).attr('id');
        var that = $(this);

        //We want to save to local storage when people edit a trix editor, so they don't lose their progress
        $(this).on('trix-change', function() {
            // Save editor state to local storage
            localStorage.setItem(trix_actual_id, JSON.stringify(editor));
        });

        //We also want to restore the trix editor states for this current page if in local storage
        if(localStorage.getItem(trix_actual_id) !== undefined && localStorage.getItem(trix_actual_id) !== null){
            editor.loadJSON(JSON.parse(localStorage.getItem(trix_actual_id)));
        }

        // input[type=file] so that users don't need to drag & drop
        var $input_file = $('<input type="file" name="file_'+trix_id+'" id="file_'+trix_id+'" class="hidden">');
        $(this).after($input_file);

        $input_file.on('change', function() {
            var element = $(this).get(0);
            if (!element.files[0]) {
                return $(this);
            }

            var file = element.files[0];

            //Fix for nasty ie bug
            if(editor === undefined){
                var editor = $(that).get(0).editor;
            }
            editor.insertFile(file);
        });

        // Help text
        $(this).after('<span class="help-block"><i class="fa fa-paperclip"></i> Drag &amp; drop files into the box above or <u data-trix-editor-upload="'+$(this).attr('input')+'">select files from your computer</u></span>');
    });

    // Help text clickable to launch corresponding input[type=file]
    $('[data-trix-editor-upload]').each(function () {
        $(this)
            .css({cursor: 'pointer'})
            .on('click', function () {
                var $corresponding_input_file = $('#file_'+$(this).attr('data-trix-editor-upload'));
                $corresponding_input_file.trigger('click');
            });
    });
});